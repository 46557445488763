// 将数字强制保留两位小数
export function mustTwoDecimal (number) {
  var num = number.toString()
  var index = num.indexOf('.')
  if (index <= 0) {
    num += '.'
    index = num.length - 1
  }
  while ((index + 3) !== num.length) {
    num += '0'
  }
  return num
}

// 时间戳转化
export function timeConversion (time) {
  var now = new Date(time * 1000)
  var year = now.getFullYear()
  var month = now.getMonth() + 1
  var date = now.getDate()
  var hour = now.getHours()
  var minute = now.getMinutes()
  var second = now.getSeconds()
  return year + '-' + month + '-' + date + '   ' + hour + ':' + minute + ':' + second
}

// 手机号码中间隐藏函数
export function hideCenterPhone (phone) {
  const phoneStart = phone.substring(0, 3)
  const phoneEnd = phone.substring(7, 11)
  return phoneStart + '****' + phoneEnd
}

// 名字开头隐藏函数
export function hideStartName (name) {
  const nameEnd = name.substring(1)
  return '*' + nameEnd
}

// // 日期处理  写在main.js里
// Vue.filter('dataFormat', dtStr => {
//   const dt = new Date(dtStr * 1000)
//   const y = dt.getFullYear()
//   const m = padZero(dt.getMonth() + 1)
//   const d = padZero(dt.getDate())

//   const hh = padZero(dt.getHours())
//   const mm = padZero(dt.getMinutes())
//   const ss = padZero(dt.getSeconds())

//   return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
// })
// function padZero(n) {
//   return n > 9 ? n : '0' + n
// }
