<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div class="content">
          <div class="base-wrapper">
            <div class="base-info">
              基本信息
            </div>
            <div class="user-info">
              <div class="left">
                <div>用户名：{{$store.getters.getUserInfo.username}}</div>
                <div>用户ID：{{$store.getters.getUserInfo.id}}</div>
                <!-- <div>密码盐：{{salt}}</div> -->
              </div>
              <div class="right">
                <el-upload class="upload" action="https://jsonplaceholder.typicode.com/posts/">
                  <div class="upload-wrapper">
                    <el-avatar :size="96" :src="$store.getters.getUserInfo.avatar"></el-avatar>
                    <span class="text">更换头像</span>
                  </div>
                </el-upload>
              </div>
            </div>
            <div class="base-info">
              安全设置
            </div>
            <!-- 修改密码 -->
            <div class="security">
              <div class="password">账号密码</div>
              <div class="set-pswd">
                <div class="left">点击右侧修改账号密码</div>
                <div class="right" @click="modify">点击修改<i ref="icon" class="el-icon-caret-bottom"></i></div>
              </div>
              <!-- 表单 -->
              <el-collapse-transition>
                <div v-if="showPasswordForm">
                  <el-form :model="passwordForm" label-width="8.33333333%" :rules="passwordRules" ref="passwordForm">
                    <!-- <el-form-item label="原密码:" prop="originalPassword">
                      <el-col :span="5">
                        <el-input v-model="passwordForm.originalPassword" placeholder="原密码"></el-input>
                      </el-col>
                    </el-form-item> -->
                    <el-form-item label="新密码:" prop="newPassword">
                      <el-col :span="5">
                        <el-input :type="pass1" v-model="passwordForm.newPassword" placeholder="至少六位密码，区分大小写">
                          <i class="input-icon" slot="suffix" :class="icon1" @click="showPassOne"></i>
                        </el-input>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="确认密码:" prop="confirmPassword">
                      <el-col :span="5">
                        <el-input :type="pass2" v-model="passwordForm.confirmPassword" placeholder="至少六位密码，区分大小写">
                          <i class="input-icon" slot="suffix" :class="icon2" @click="showPassTwo"></i>
                        </el-input>
                      </el-col>
                    </el-form-item>
                    <el-form-item>
                      <el-button @click="passwordBtn" type="primary" size="medium">确认修改</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </el-collapse-transition>
            </div>
            <!-- 修改手机号码 -->
            <div class="setPhone">
              <div class="password">手机号码</div>
              <div class="set-pswd">
                <div class="left">已绑定手机：{{hideCenterPhone($store.getters.getUserInfo.username)}}</div>
                <div class="right" @click="modifyPhone">点击修改<i ref="icon2" class="el-icon-caret-bottom"></i></div>
              </div>
              <!-- 表单 -->
              <el-collapse-transition>
                <div v-if="showPhoneForm" class="phone-form">
                  <el-form :model="phoneForm" label-width="8.33333333%" :rules="phoneRules" ref="phoneForm">
                    <el-form-item label="原手机号码:" prop="originalPhone">
                      <el-col :span="5">
                        <el-input v-model="phoneForm.originalPhone" disabled placeholder="原手机号码"></el-input>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="验证码:" prop="originalPhoneCode">
                      <el-col :span="5" class="flex">
                        <el-input class="phone-code" v-model="phoneForm.originalPhoneCode" placeholder="手机验证码"></el-input>
                        <el-button @click="getCode1" :disabled="!codeFlag1" class="btn-code">{{codeBtnText1}}</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="新手机号码:" prop="newPhone">
                      <el-col :span="5">
                        <el-input v-model="phoneForm.newPhone" placeholder="新手机号"></el-input>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="验证码:" prop="newPhoneCode">
                      <el-col :span="5" class="flex">
                        <el-input class="phone-code" v-model="phoneForm.newPhoneCode" placeholder="手机验证码"></el-input>
                        <el-button @click="getCode2" :disabled="!codeFlag2" class="btn-code">{{codeBtnText2}}</el-button>
                      </el-col>
                    </el-form-item>
                    <el-form-item>
                      <el-button @click="phoneBtn" type="primary" size="medium">确认修改</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </el-collapse-transition>
            </div>
            <!-- 修改提现支付宝账号 -->
            <div class="setAlipay">
              <div class="password">提现支付宝账号</div>
              <div class="set-pswd">
                <div class="left">已绑定账号：{{hideCenterPhone($store.getters.getUserInfo.username) + '（' + hideStartName(trueName) + '）'}}</div>
                <div class="right" @click="clickAipay">点击修改<i ref="icon3" class="el-icon-caret-bottom"></i></div>
              </div>
              <!-- 表单 -->
              <el-collapse-transition>
                <div v-if="showAipayForm">
                  <el-form :model="aiPayForm" label-width="8.33333333%" :rules="aipayRules" ref="aipayForm">
                    <el-form-item label="支付宝账号:" prop="aiPayNumber">
                      <el-col :span="5">
                        <el-input v-model="aiPayForm.aiPayNumber" placeholder="请输入支付宝账号"></el-input>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="真实姓名:" prop="aiPayName">
                      <el-col :span="5">
                        <el-input v-model="aiPayForm.aiPayName" placeholder="真实姓名"></el-input>
                      </el-col>
                    </el-form-item>
                    <el-form-item>
                      <el-button @click="aiPayBtn" type="primary" size="medium">确认修改</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </el-collapse-transition>
            </div>
          </div>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { resetpwd, resetzfb, getCode, changemobile, getUserMoney } from 'network/api'
import { hideCenterPhone, hideStartName } from 'utils/utils'

export default {
  components: {
    AppMain
  },
  data () {
    // 自定义验证方法
    // 原始密码
    // var validatePass = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error('请输入原密码'))
    //   } else {
    //     callback()
    //   }
    // }
    // 新密码
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入新密码'))
      } else {
        if (this.passwordForm.confirmPassword !== '') {
          this.$refs.passwordForm.validateField('confirmPassword')
        }
        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(value)) {
          callback(new Error('由数字和字母组成,长度在6到16位之间'))
        }
        callback()
      }
    }
    // 确认密码
    var validatePass3 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('由数字和字母组成,长度在6到16位之间'))
      } else if (value !== this.passwordForm.newPassword) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    // 原手机号码
    var validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码'))
      } else if (!/^1[3-9]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    // 原手机号码验证码
    var validatePhoneCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入验证码'))
      } else {
        callback()
      }
    }
    // 新手机号码
    var validateNewPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码'))
      } else if (!/^1[3-9]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    // 新手机号码验证码
    var validateNewPhoneCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入验证码'))
      } else {
        callback()
      }
    }
    // 支付宝账号验证
    var validatorAipayNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入正确的支付宝账号'))
      } else {
        callback()
      }
    }
    // 支付宝真实姓名验证
    var validatorAipayName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入真实姓名'))
      } else if (!/^[\u4e00-\u9fa5]+$/.test(value)) {
        callback(new Error('请输入正确的姓名'))
      } else {
        callback()
      }
    }
    return {
      trueName: '',
      salt: '',
      showPasswordForm: false,
      showPhoneForm: false,
      showAipayForm: false,
      passwordForm: {
        // originalPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      phoneForm: {
        originalPhone: '',
        originalPhoneCode: '',
        newPhone: '',
        newPhoneCode: ''
      },
      aiPayForm: {
        aiPayNumber: '',
        aiPayName: ''
      },
      icon1: 'el-icon-view', // 改变图标
      pass1: 'password', // 改变input类型
      icon2: 'el-icon-view', // 改变图标
      pass2: 'password', // 改变input类型
      timer1: null,
      codeFlag1: true,
      codeBtnText1: '获取验证码',
      timer2: null,
      codeFlag2: true,
      codeBtnText2: '获取验证码',
      // 密码表单验证规则
      passwordRules: {
        // originalPassword: [
        //   { validator: validatePass, trigger: 'blur' }
        // ],
        newPassword: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        confirmPassword: [
          { validator: validatePass3, trigger: 'blur' }
        ]
      },
      // 手机号码验证规则
      phoneRules: {
        originalPhone: [
          { validator: validatePhone, trigger: 'blur' }
        ],
        originalPhoneCode: [
          { validator: validatePhoneCode, trigger: 'blur' }
        ],
        newPhone: [
          { validator: validateNewPhone, trigger: 'blur' }
        ],
        newPhoneCode: [
          { validator: validateNewPhoneCode, trigger: 'blur' }
        ]
      },
      // 修改支付宝验证规则
      aipayRules: {
        aiPayNumber: [
          { validator: validatorAipayNumber, trigger: 'blur' }
        ],
        aiPayName: [
          { validator: validatorAipayName, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    hideCenterPhone () {
      return hideCenterPhone
    },
    hideStartName () {
      return hideStartName
    }
  },
  created () {
    this.phoneForm.originalPhone = this.$store.getters.getUserInfo.username
    // console.log(this.$store.getters.getUserInfo)
    getUserMoney().then(res => {
      this.trueName = res.data.true_name
      this.salt = res.data.salt
    })
  },
  methods: {
    // 点击修改密码
    modify () {
      this.showPasswordForm = !this.showPasswordForm // 控制密码表单form显示
      // 切换icon
      if (this.$refs.icon.className === 'el-icon-caret-bottom') {
        this.$refs.icon.className = 'el-icon-caret-top'
      } else {
        this.$refs.icon.className = 'el-icon-caret-bottom'
      }
    },
    // 点击修改手机号码
    modifyPhone () {
      this.showPhoneForm = !this.showPhoneForm // 控制手机号码表单form显示
      // 切换icon
      if (this.$refs.icon2.className === 'el-icon-caret-bottom') {
        this.$refs.icon2.className = 'el-icon-caret-top'
      } else {
        this.$refs.icon2.className = 'el-icon-caret-bottom'
      }
    },
    // 点击修改支付宝账号
    clickAipay () {
      this.showAipayForm = !this.showAipayForm // 控制手机号码表单form显示
      // 切换icon
      if (this.$refs.icon3.className === 'el-icon-caret-bottom') {
        this.$refs.icon3.className = 'el-icon-caret-top'
      } else {
        this.$refs.icon3.className = 'el-icon-caret-bottom'
      }
    },
    // 确认修改密码
    passwordBtn () {
      // 效验规则是否成功
      this.$refs.passwordForm.validate(valid => {
        if (valid) {
          // 修改密码
          resetpwd(this.passwordForm.newPassword).then(res => {
            // 修改失败
            if (res.code === 0) {
              this.$message.error(res.msg)
            }
            // 修改成功
            if (res.code === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
              // 重新登录
              const _this = this
              setTimeout(function () {
                _this.$router.push('/login')
              }, 1500)
            }
          })
        } else {
          return false
        }
      })
    },
    // 获取原手机号验证码
    getCode1 () {
      let time = 60
      if (!this.timer1) {
        this.timer1 = setInterval(() => {
          if (time > 0) {
            this.codeFlag1 = false
            this.codeBtnText1 = time + 's后重新发送'
            time--
          } else {
            clearInterval(this.timer1)
            this.codeFlag1 = true
            this.codeBtnText1 = '获取验证码'
            this.timer1 = null
          }
        }, 1000)
      }
      // 获取验证码
      getCode(this.phoneForm.originalPhone, 'changemobile').then(res => {
        this.$message(res.msg)
      })
    },
    // 获取新手机号验证码
    getCode2 () {
      let time = 60
      if (!this.timer2) {
        this.timer2 = setInterval(() => {
          if (time > 0) {
            this.codeFlag2 = false
            this.codeBtnText2 = time + 's后重新发送'
            time--
          } else {
            clearInterval(this.timer2)
            this.codeFlag2 = true
            this.codeBtnText2 = '获取验证码'
            this.timer2 = null
          }
        }, 1000)
      }
      // 获取验证码
      getCode(this.phoneForm.newPhone, 'changemobile').then(res => {
        this.$message(res.msg)
      })
    },
    // 确认修改手机号码
    phoneBtn () {
      // 效验规则是否成功
      this.$refs.phoneForm.validate(valid => {
        if (valid) {
          // 成功
          changemobile(this.phoneForm.newPhone, this.phoneForm.newPhoneCode).then(res => {
            if (res.code === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
              // 重新登录
              const _this = this
              setTimeout(function () {
                _this.$router.push('/login')
              }, 1500)
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          // 失败
          return false
        }
      })
    },
    // 修改支付宝账号
    aiPayBtn () {
      this.$refs.aipayForm.validate(valid => {
        if (valid) {
          // console.log(this.aiPayForm.aiPayName)
          if (this.trueName === this.aiPayForm.aiPayName) {
            resetzfb(this.aiPayForm.aiPayNumber, this.aiPayForm.aiPayName).then(res => {
              // 修改失败
              if (res.code === 0) {
                this.$message.error(res.msg)
              }
              // 修改成功
              if (res.code === 1) {
                this.$message({
                  message: res.msg,
                  type: 'success'
                })
                // 刷新页面
                setTimeout(function () {
                  location.reload()
                }, 1000)
              }
            })
          } else {
            this.$message.error('请输入正确名字')
          }
        } else {
          return false
        }
      })
    },
    // 密码显示1
    showPassOne () {
      if (this.pass1 === 'password') {
        this.pass1 = 'text'
        this.icon1 = 'el-icon-loading'
      } else {
        this.pass1 = 'password'
        this.icon1 = 'el-icon-view'
      }
    },
    // 密码显示2
    showPassTwo () {
      if (this.pass2 === 'password') {
        this.pass2 = 'text'
        this.icon2 = 'el-icon-loading'
      } else {
        this.pass2 = 'password'
        this.icon2 = 'el-icon-view'
      }
    }
  }
}
</script>

<style scoped src="styles/views/account/base.css">
</style>

<style>
.phone-code .el-input__inner {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.btn-code.el-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
